#root {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}
/********** margin padding function start here **************/
.gap-0 {
  gap: 0px;
}
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.gap-4 {
  gap: 4px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.gap-8 {
  gap: 8px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.gap-16 {
  gap: 16px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.gap-24 {
  gap: 24px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.gap-32 {
  gap: 32px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.gap-40 {
  gap: 40px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.gap-48 {
  gap: 48px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.wrapper {
  padding: 24px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    font-size: 12px;
    padding: 24px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 16px;
    padding-bottom: 0;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 18px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: 700;
}
/********** text alignment end here **************/
/********** font size start here **************/
.font-large {
  font-size: larger;
}
.font-medium {
  font-size: medium;
}
/********** font size end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-wrapper .ant-avatar {
  background: #7878dd;
}
.header-wrapper button {
  height: auto;
}
.header-wrapper button svg {
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 16px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #121d45;
}
#logo img {
  height: auto;
  width: 80%;
}
.delete-icon {
  color: #e92424;
  cursor: pointer;
}
/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}
.trigger {
  font-size: 16px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
/*sidebar custom less start here*/
.responsive-logo img {
  width: 44px;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}
.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}
.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px !important;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  color: #545454;
  line-height: 64px;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  color: transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  border-bottom: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
/*ant card body scroll class*/
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 12px;
}
/*fleg dropdown css end here*/
/*height & width for loginpage logo*/
.login-page-logo {
  width: 70%;
}
.btn-form-save {
  border-radius: 8px;
  font-weight: 500;
}
.btn-form-cancel {
  border-radius: 8px;
  font-weight: 500;
}
.side-bar-text > .active {
  color: #ffffff;
}
.listing-card {
  overflow: auto;
}
.secondary-btn {
  background: #32d995;
}
/*ui improvement css start here*/
.d-statistics .st-icon svg {
  width: 60px;
  height: auto;
}
.dashboard-table-card .ant-card-body {
  padding: 0;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  font-weight: 600;
}
footer {
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #29284b;
  align-items: center;
}
@media only screen and (max-width: 540px) {
  footer {
    flex-direction: column;
  }
}
footer img {
  height: 30px;
}
.page-card .ant-card-body {
  padding: 0;
}
.statastics-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
}
.statastics-card {
  padding: 30px 20px;
  flex: 0 0 calc(33.33% - 20px);
  background: #f0f2ff;
  border-radius: 12px;
  color: #29284b;
  margin: 10px;
}
@media only screen and (max-width: 1024px) {
  .statastics-card {
    flex: 0 0 calc(50% - 20px);
  }
}
@media only screen and (max-width: 540px) {
  .statastics-card {
    flex: 0 0 calc(100% - 20px);
  }
}
.statastics-card svg {
  width: 50px;
  height: auto;
  background: #7878dd;
  border-radius: 50%;
  padding: 5px;
}
.statastics-card svg path {
  fill: #f0f2ff;
}
.statastics-card h2 {
  color: #29284b;
  margin: 0;
}
.statastics-card p {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.no-game-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px 0;
  font-size: 16px;
  font-weight: 600;
}
.no-game-data svg {
  width: 300px;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}
.responsive-charts {
  overflow: hidden;
}
.responsive-logo {
  height: 64px;
}
.responsive-table {
  overflow: auto;
}
.side-bar .ant-menu-light .ant-menu-item-selected {
  background: linear-gradient(72.47deg, #7367f0 22.16%, rgba(115, 103, 240, 0.7) 76.47%);
  box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48);
  color: #fff;
}
.side-bar .ant-menu-light .ant-menu-item-selected svg path {
  fill: #bbbbee;
}
.d-statistics .ant-card-bordered {
  border-bottom: 3px solid #c7c2f9;
  transition: all 0.4s;
}
.d-statistics .ant-card-bordered:hover {
  border-bottom: 3px solid #7878dd;
}
@media only screen and (max-width: 767px) {
  .ant-pagination .ant-pagination-options {
    display: block;
  }
}
.ant-pagination .ant-pagination-next {
  background-color: rgba(75, 70, 92, 0.08);
}
@media only screen and (max-width: 767px) {
  .ant-pagination .ant-pagination-next button.ant-pagination-item-link {
    padding: 8px 10px;
  }
}
.ant-pagination .ant-pagination-prev {
  background-color: rgba(75, 70, 92, 0.08);
}
@media only screen and (max-width: 767px) {
  .ant-pagination .ant-pagination-prev button.ant-pagination-item-link {
    padding: 8px 10px;
  }
}
.ant-pagination .ant-pagination-item {
  background-color: rgba(75, 70, 92, 0.08);
}
@media only screen and (max-width: 767px) {
  .ant-pagination .ant-pagination-item {
    display: none;
  }
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  background: #7878dd;
  border: #7878dd;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 5px 22px;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 5px 22px;
}
.ant-card.side-bar-card .ant-card-head {
  padding: 0;
  border: 0;
}
.ant-card.side-bar-card .ant-card-head .ant-card-head-title {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  flex: 0 0 100%;
}
.ant-card.side-bar-card .ant-card-head .ant-tabs-nav {
  width: 100%;
}
.ant-card.side-bar-card .ant-card-head .ant-tabs-nav-list {
  margin-bottom: 16px;
}
.ant-card.side-bar-card .ant-card-body {
  padding: 0;
}
.game-leader-board {
  overflow: auto;
}
/*ui improvement css end here*/
/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*typography css end here*/
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
